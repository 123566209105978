import React, { useState } from "react";

const AccordionItem = ({ q, a, strokeColor }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <li className={`q${open ? ` active` : ``}`}>
        <button
          onClick={() => setOpen(!open)}
          className="button-as-link w-100 d-flex"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 24 24"
            fill="none"
            stroke={strokeColor || "#2d69b0"}
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="accordion-icon"
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
          {q}
        </button>
      </li>
      <li className="a">
        <ul>
          {a.map((item, idx) => (
            <Answer key={idx} answer={item} />
          ))}
        </ul>
      </li>
    </>
  );
};

const Answer = ({ answer }) => (
  <li dangerouslySetInnerHTML={{ __html: answer }} />
);

// A 6-button touch panel in living and dining area A handy 4-button smart panel by the bedside Additional Features - Main Door intrusion detection Bathroom lighting fitted with motion sensors All bedrooms air-conditioning with on / off control Fully automated air-conditioning for living / dining area Gas leak sensor Bathroom geyser with on / off control"

export default AccordionItem;
