import React, {useCallback, useState} from "react";
import Img from "gatsby-image";
import MyCarousel from "../common/MyCarousel";
import useMedia from "use-media";
import GalleryModal from "../gallery/GalleryModal";

function FloorplanCarousel({
                               rootClass = '',
                               title = "",
                               pricing = "",
                               rera = "",
                               floorPlan = []
                           }) {
    const isWide = useMedia({minWidth: "700px"});
    const images = floorPlan.map(x => x.childImageSharp.fluid);

    return (
        <div className={rootClass}>
            <div className="pb-2 d-flex justify-content-between align-items-center flex-wrap">
                <div className="pr-2">
                    {title && (
                        <div
                            className="font-weight-bold mb-1"
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                    )}
                    {pricing && (
                        <div
                            className="mb-1"
                            dangerouslySetInnerHTML={{__html: pricing}}
                        />
                    )}
                </div>
                {rera && <small>{rera}</small>}
            </div>
            <MyCarousel
                slidesToShow={isWide ? 3 : 1}
                wrapAround
                cellSpacing={10}
                framePadding="0px 0px 20px 0px"
            >
                {images.map((img, idx) => (
                    <FloorPlanItem key={idx} idx={idx} img={img} images={images}/>
                ))}
            </MyCarousel>
        </div>
    );
}

const FloorPlanItem = ({idx, img, images}) => {
    const [active, set] = useState(null);

    const onClose = useCallback(() => {
        set(null);
    }, []);

    return (
        <>
            <button
                className="w-100 button-as-link zoom-in-cursor"
                onClick={() => set(idx)}
            >
                <Img
                    imgStyle={{
                        objectFit: "contain"
                    }}
                    fluid={img}
                    alt=""
                />
            </button>
            <GalleryModal slideIndex={active} images={images} onClose={onClose}/>
        </>
    );
};

export default FloorplanCarousel;
