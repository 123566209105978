import React, { useState, useEffect } from "react";

const WalkThrough = ({ walk_through = [] }) => {
  if (walk_through.length > 0) {
    return <WalkThroughTabs walk_through={walk_through} />;
  }
  return null;
};

const WalkThroughTabs = ({ walk_through = [] }) => {
  const [tab, setTab] = useState(0);
  const galleryLength = walk_through.length;

  useEffect(() => {
    setTab(0);
  }, [galleryLength]);

  return (
    <>
      {galleryLength > 1 && (
        <div className="gallery-tabs">
          {walk_through.map((item, idx) => (
            <button
              key={item.title}
              onClick={() => setTab(idx)}
              className={`btn${tab === idx ? ` active` : ""}`}
            >
              {item.title}
            </button>
          ))}
        </div>
      )}
      {walk_through[tab] && (
        <div>
          <iframe
            title={walk_through[tab].title}
            className="walk-through-iframe"
            src={walk_through[tab].urls}
            frameBorder="0"
            allowFullScreen
            allow="xr-spatial-tracking"
          />
        </div>
      )}
    </>
  );
};

export default WalkThrough;
