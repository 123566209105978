import React from "react";

export default function ResidentSection({ children, title, id }) {
  return (
    <div className="pb-4">
      <a
        href={`#${id}`}
        id={id}
        aria-hidden="true"
        className="resident-scroll-link"
      />
      <h5 className="residence-section-title">{title}</h5>
      {children}
    </div>
  );
}
