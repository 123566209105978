import React from "react";
import AccordionItem from "./AccordionItem";
import FloorplanCarousel from "./FloorplanCarousel";
import ProjectGallery from "./ProjectGallery";
import ResidentSection from "./ResidentSection";
import Img from "gatsby-image";
import WalkThrough from "./Walkthrough";

export const SectionCon = ({children}) => {
    return (
        <section className="residence-section">
            <div className="container">{children}</div>
        </section>
    );
};

export const Specifications = ({specification = []}) => {
    if (Array.isArray(specification) && specification.length > 0) {
        return (
            <ResidentSection
                id="project-specifications"
                title="Project Specifications"
            >
                <ul className="accordion">
                    {specification.map((item, idx) => (
                        <AccordionItem key={idx} {...item} />
                    ))}
                </ul>
            </ResidentSection>
        );
    }
    return null;
};

export const FloorPlan = ({floorPlan = []}) => {
    if (Array.isArray(floorPlan) && floorPlan.length > 0) {
        return (
            <ResidentSection id="floor-plan" title="Floor Plan">
                {floorPlan.map((item, idx) => (
                    <FloorplanCarousel
                        rootClass={(idx + 1) < floorPlan.length ? 'mb-4' : ''}
                        title={item.title}
                        rera={item.rera}
                        pricing={item.pricing}
                        floorPlan={Array.isArray(item.images) ? item.images : []}
                        key={idx + item.title}
                    />
                ))}
            </ResidentSection>
        );
    }
    return null;
};

export const Gmap = ({gMapUrl = "", projectLogo = null, address = ""}) => {
    if (!gMapUrl) {
        return null;
    }
    return (
        <ResidentSection id="location" title="Location">
            <div className="row">
                <div className="col-md-9 mb-3" style={{height: `250px`}}>
                    <iframe
                        title="google-maps"
                        src={gMapUrl}
                        height={250}
                        frameBorder={0}
                        style={{border: 0, width: "100%"}}
                        allowFullScreen
                    />
                </div>
                <div className="col-md-3 mb-3 d-flex justify-content-md-center flex-md-column align-items-center">
                    {projectLogo && projectLogo.childImageSharp && (
                        <Img
                            style={{maxWidth: "100px"}}
                            className="w-100"
                            fluid={projectLogo.childImageSharp.fluid}
                        />
                    )}
                    <div className="p-3 text-sm-center">
                        <small>{address}</small>
                    </div>
                </div>
            </div>
        </ResidentSection>
    );
};

export const Synopsis = ({synopsis = ""}) => {
    return (
        <ResidentSection id="synopsis" title="Project Synopsis">
            <p className="residence-section-desc text-justify m-0">{synopsis}</p>
        </ResidentSection>
    );
};

export const GallerySection = ({title = "Gallery", gallery = []}) => {
    const slug = title.replace(" ", "-").toLowerCase();
    if (Array.isArray(gallery) && gallery.length > 0) {
        return (
            <ResidentSection id={slug} title={title}>
                <ProjectGallery gallery={Array.isArray(gallery) ? gallery : []}/>
            </ResidentSection>
        );
    }
    return <></>;
};

export const WalkThroughSection = ({walk_through = []}) => {
    return (
        <ResidentSection id="walk-through" title="Walkthrough">
            <WalkThrough
                walk_through={Array.isArray(walk_through) ? walk_through : []}
            />
        </ResidentSection>
    );
};
