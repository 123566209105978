import React, { useState, useEffect } from "react";
import GalleryList from "../gallery/GalleryList";

const ProjectGallery = ({ gallery = [] }) => {
  if (gallery.length > 0) {
    return <ProjectGalleryTabs gallery={gallery} />;
  }
  return null;
};

const ProjectGalleryTabs = ({ gallery = [] }) => {
  const [tab, setTab] = useState(0);
  const galleryLength = gallery.length;

  useEffect(() => {
    setTab(0);
  }, [galleryLength]);

  const reverseGallery = [...gallery].reverse();

  //   console.log("reverseGallery", reverseGallery);
  //   console.log("normal", gallery);

  return (
    <>
      {galleryLength > 0 && (
        <div className="gallery-tabs">
          {reverseGallery.map((item, idx) => {
            if (item.title) {
              return (
                <button
                  key={item.title}
                  onClick={() => setTab(idx)}
                  className={`btn${tab === idx ? ` active` : ""}`}
                >
                  {item.title}
                </button>
              )
            }
            return null
          })}
        </div>
      )}
      {reverseGallery[tab] && (
        <GalleryList
          images={(reverseGallery[tab].images || []).map(
            x => x.childImageSharp.fluid
          )}
          videos={reverseGallery[tab].videos || []}
        />
      )}
    </>
  );
};

export default ProjectGallery;
